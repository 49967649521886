<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <span v-if="snackbar_reset">
          <app-alert
            @dismissed="sb_reset_action"
            text_style="success"
            text="Success! Please check your email for the password reset link."
          />
        </span>

        <span v-if="error">
          <app-alert @dismissed="onDismissed" :text="error" text_style="error" />
        </span>
        <v-card class="elevation-4">
          <v-card-text align="center">
            <v-img src="@/assets/images/VentureWallet_icon_light.png" height="80px" contain />
          </v-card-text>
          <v-card-text align="center">
            <h1>Forgot Password</h1>
          </v-card-text>
          <v-spacer />

          <v-card-text>
            <v-form ref="form" @submit.prevent="resetPass">
              <v-text-field
                label="Email"
                name="email"
                prepend-icon="person"
                type="text"
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                v-model="email"
                required
              />

              <v-btn
                type="submit"
                color="primary"
                class="white--text"
                x-large
                block
                :loading="isLoading"
              >Email My Reset Link</v-btn>
            </v-form>
          </v-card-text>
          <v-card-text align="center" />
        </v-card>
      </v-col>
    </v-row>
    <p style="text-align: center;">
      <router-link to="/signin" class="routerlink">Sign In</router-link>&nbsp;&nbsp;or&nbsp;
      <router-link to="/register" class="routerlink">Create Your Account</router-link>
    </p>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "Forgotpassword",
  mixins: [validationMixin],

  validations: {
    email: { required, email }
  },
  data: function() {
    return {
      email: ""
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    ...mapGetters(["isLoading", "error", "snackbar_reset"])
  },
  methods: {
    resetPass() {
      this.$store.dispatch("resetPass", this.email);
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
    sb_reset_action() {
      this.$store.dispatch("clearResetPass");
    }
  }
};
</script>
